
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable dot-notation */
import { mapMutations } from 'vuex';
import ApiService from '@/services/liveInformation-service.js';
import map from '@/components/GoogleMap';
import moment from 'moment';
import Jessibuca from '@/components/Jessibuca';
export default {
  components: { gmap: map, Jessibuca },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  props: {
    center: {
      type: Object,
      default: () => ({ lat: 22.3186, lng: 114.1796 }),
    },
  },
  data: () => ({
    timeLeftToLogout: '',
    idleReminderDialog: false,
    banner: false,
    drawer: true,
    pagination: [],
    vehicleList: [], // Origin list
    perVehicleList: [], // Per page
    checkVehicleList: [], // After boxtype list
    filterList: [], // After filter list
    getVehicleList: [], // final get list
    markers: [],
    selectedItem: null,
    currentPage: 1,
    currentPlate: '',
    perPage: 10,
    onlineCount: 0,
    offlineCount: 0,
    stoppedCount: 0,
    activeCount: 0,
    active: false,
    inactiveCount: 0,
    inactive: false,

    search: 'search',
    liveStatus: false,
    LiveCheckboxType: [],
    LiveCheckActive: [],
    filterInput: [],
    googleMapApi: [],
    liveHost: 'https://video.gstsafety.com/liveplayer/live_4.html?plateNo=',
    historyHost: 'https://video.gstsafety.com/liveplayer/vod.html?plateNo=',
    // * ttx live video
    ttxLiveVideoSection: false,
    ttxifrm: null,
    // currentCompany: '',
    showLive: false,
    // showHistory: false,
    getLiveDataTimer: '',
    lastUpdate: '',

    ShowLicensePlate: false,
    hasBSD: false,
    // hardcode company not to show active/inactive count
    hardcodeCompany: ['cocacola', 'cocacolacp'],
    // * channels
    channelNumber: [],
  }),
  computed: {
    activeCountLabel: {
      get() {
        let label = this.$t('Live.Active');
        if (!this.hardcodeCompany.includes(this.$store.state.app.company)) {
          label = label + '(' + this.activeCount + ')';
        }
        return label;
      },
    },
    inactiveCountLabel: {
      get() {
        let label = this.$t('Live.Inactive');
        if (!this.hardcodeCompany.includes(this.$store.state.app.company)) {
          label = label + '(' + this.inactiveCount + ')';
        }
        return label;
      },
    },
  },
  watch: {
    // ! to remove listener when close the ttx live video panel
    ttxLiveVideoSection(val) {
      if (!val) {
        window.removeEventListener('message', this.ttxiframeListener, false);
        document.removeEventListener(
          'fullscreenchange',
          this.fullScreenListener
        );
      }
    },
    getVehicleList(val, oldVal) {
      if (val.length !== oldVal.length) {
        //* page count depends on this getVehicleList so quick hack
        //* go back to 1st page
        this.currentPage = 1;
        //* next() to show the list in page
        this.next();
      }
    },
    LiveCheckboxType(newName) {
      this.changeType(newName);
    },
    filterInput(newValue) {
      this.applyFilter(newValue);
    },
    // Change company
    '$store.state.app.company'(val) {
      this.getLive();
      // this.currentCompany = val;
      this.currentPage = 1;
      this.banner = false;
    },
    '$store.state.app.liveStreaming'(val) {
      this.showLive = false;
      if (val) {
        this.showLive = true;
      }
    },
    // '$store.state.app.historyRoute'(val) {
    //   this.showHistory = false;
    //   if (val) {
    //     this.showHistory = true;
    //   }
    // },
  },
  mounted() {
    this.getLive();
    this.showLive = this.$store.state.app.liveStreaming;

    this.getLiveDataTimer = setInterval(() => {
      console.log(`reload getLive: ${moment(new Date()).format('HH:mm:ss')}`);
      this.getLive();
    }, 60 * 1000);

    this.$watch(
      () => {
        return this.$refs.idle.display;
      },
      (val) => {
        this.timeLeftToLogout = val;
        if (val === '30:00') {
          this.idleReminderDialog = false;
        }
      }
    );
  },
  beforeRouteLeave(to, from, next) {
    // ! to remove listener when leaving page
    document.removeEventListener('fullscreenchange', this.fullScreenListener);
    window.removeEventListener('message', this.ttxiframeListener, false);
    console.log('liveinfo beforeRouteLeave: stop getLive');
    clearInterval(this.getLiveDataTimer);
    next();
  },
  methods: {
    ...mapMutations('app', ['setLocations', 'getVehicle']),
    getLive() {
      ApiService.getLive(this.$axios)
        .then((response) => {
          if (response.items) {
            this.vehicleList = response.items;

            this.LiveCheckboxType = [];
            this.onlineCount = response.status.onlineCount;
            if (this.onlineCount) this.LiveCheckboxType.push('online');
            this.offlineCount = response.status.offlineCount;
            if (this.offlineCount) this.LiveCheckboxType.push('offline');
            this.stoppedCount = response.status.stoppedCount;
            if (this.stoppedCount) this.LiveCheckboxType.push('stopped');
            this.activeCount = response.status.activeCount;
            if (this.activeCount > 0) this.LiveCheckboxType.push('active');
            this.inactiveCount = response.status.inactiveCount;

            // Set Map
            this.getVehicleList = this.vehicleList;

            this.lastUpdate = moment(new Date()).format('HH:mm');
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log('There was an error: ');
          console.log(error);
        });
    },
    getLiveStatusItem(value) {
      // Check rwd
      if (this.$vuetify.breakpoint.smAndDown) {
        this.drawer = false;
      }
      this.currentPlate = value.liveVideoPlate;
      this.getVehicle(value);

      this.hasBSD = value?.hasBSD;
      this.channelNumber = value?.channelNumber || [];
      // tBoxProviderId !== 4
      this.banner = false;
      // if (this.showLive) {
      //   if (value.tBoxProviderId !== 4) {
      //     this.playMonitor(value.liveVideoPlate);
      //   }
      // }
    },
    // CheckBox type
    changeType(newName) {
      const typeList = [];
      const activeList = [];
      newName.forEach((type) => {
        if (type !== 'active' && type !== 'inactive') {
          typeList.push(type);
        } else {
          activeList.push(type);
        }
      });
      this.checkVehicleList = [];
      this.vehicleList.forEach((item) => {
        typeList.forEach((type) => {
          if (item.status === type) {
            if (item.isActive && activeList.includes('active')) {
              this.checkVehicleList.push(item);
            }
            if (!item.isActive && activeList.includes('inactive')) {
              this.checkVehicleList.push(item);
            }
          }
        });
      });
      this.applyFilter(this.filterValue);
    },
    // Filter
    applyFilter(filterValue) {
      this.filterValue = filterValue;
      this.filterList = [];
      if (this.filterValue) {
        if (this.checkVehicleList) {
          this.filterList = this.checkVehicleList.filter((post) => {
            return (
              post.plate
                .toString()
                .toLowerCase()
                .includes(filterValue.toLowerCase().trim()) ||
              post.department
                .toString()
                .toLowerCase()
                .includes(filterValue.toLowerCase().trim())
            );
          });
        }
      }
      this.getVehicleList = [];
      if (this.filterList.length > 0) {
        this.getVehicleList = this.filterList;
      } else if (!this.filterValue) {
        this.getVehicleList = this.checkVehicleList;
      }
      this.getVehicleList = this.getVehicleList.sort(function (a, b) {
        return new Date(b.lastRunUpdatedAt) - new Date(a.lastRunUpdatedAt);
      });
      this.setMap(this.getVehicleList);
    },
    // Splice page
    next() {
      this.perVehicleList = [];
      this.perVehicleList = this.getVehicleList.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      );
    },
    // Set Location
    setMap(list) {
      // Set pagination
      this.next();
      // Set Map
      this.setLocations(list);
    },
    setSelected(val) {
      let num = 0;
      this.getVehicleList.forEach((item, i) => {
        if (item.plate === val.plate) {
          num = i === 0 ? 0 : i / 10;
          this.selectedItem = i % 10;
          // if (item.tBoxProviderId !== 4) {
          //   if (this.showLive) {
          //     this.playMonitor(val);
          //   }
          // }

          this.channelNumber = item.channelNumber || [];
        }
      });
      this.currentPage = Math.floor(num) + 1;
      this.next();
      // if (this.$store.state.app.liveStreaming) {
      //   if (val.tBoxProviderId !== 4) {
      //     this.playMonitor(val);
      //   }
      // }
      this.currentPlate = val.liveVideoPlate;
    },
    // * play ttx history
    playTTXHistory(plate) {
      window.open(
        `https://video.gstsafety.com/playback?PlateNum=${plate}&lang=${
          this.$store.state.app.locale === 'en' ? 'en' : 'zh'
        }`,
        'newWindow'
      );
    },
    // * play ttx live video
    playTTXLiveVideo(plate) {
      this.ttxLiveVideoSection = true;
      const $this = this;
      this.$nextTick(() => {
        $this.ttxifrm = document.getElementById('ttxifrm');
        // this.ttxifrm.addEventListener('load', () => { });
        $this.ttxifrm.src = `https://video.gstsafety.com/livevideo?lang=${
          $this.$store.state.app.locale === 'en' ? 'en' : 'zh'
        }&vehiIdno=${plate}&chns=2,0,1,3,4,5`;

        // ! add listening to ttx iframe
        window.addEventListener('message', this.ttxiframeListener);
        document.addEventListener('fullscreenchange', this.fullScreenListener);
      });
    },
    // ! only ttx iframe
    fullScreenListener() {
      console.log('fullScreenListener element', document.fullscreenElement);
      // ! notify the iframe to re size
      if (document.fullscreenElement) {
        this.$nextTick(() => {
          this.ttxifrm?.contentWindow.postMessage(
            { fn: 'hackfullscreen' },
            '*'
          );
        });
      } else {
        this.ttxifrm?.contentWindow.postMessage({ fn: 'locationreload' }, '*');
      }
    },
    ttxiframeListener(event) {
      console.log(event.data);
      // ! this occurs when double click
      if (event.data.type === 'oneMax') {
        this.$nextTick(() => {
          // ! ttx iframe enter full screen
          this.ttxifrm?.requestFullscreen();
        });
      }
    },
    //* play realtime video
    playMonitor(value) {
      // if (this.Company === 'clp') {
      this.banner = false;
      this.banner = true;
      this.livePath = this.liveHost + value + '&channel=';
      window.onbeforeunload = null;
      // }
      this.currentPlate = value;
    },
    setFullScreen(item) {
      if (
        document.fullscreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.msFullscreenEnabled
      ) {
        // which element will be fullscreen
        const iframe = document.getElementById('video' + item);
        // Do fullscreen
        if (iframe.requestFullscreen) {
          iframe.requestFullscreen();
        } else if (iframe.webkitRequestFullscreen) {
          iframe.webkitRequestFullscreen();
        } else if (iframe.mozRequestFullScreen) {
          iframe.mozRequestFullScreen();
        } else if (iframe.msRequestFullscreen) {
          iframe.msRequestFullscreen();
        }
      } else {
        document.querySelector('.error').innerHTML =
          'Your browser is not supported';
      }
    },
    playHistory() {
      window.open(this.historyHost + this.currentPlate, 'newWindow');
    },

    onRemind() {
      this.idleReminderDialog = true;
    },
    onIdle() {
      this.$msal.logoutRedirect();
    },
    onIdleCloseVideo() {
      this.ttxLiveVideoSection = false;
      this.banner = false;
    },
  },
};
