
import axios from 'axios';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-rotate-player-plugin';

export default {
  props: {
    value: Boolean,
    media: {
      type: String,
      default: '',
    },
    rotate: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      playerOptions: {
        autoplay: 'muted',
        controls: true,
        // height: '460',
        restoreEl: true,
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        responsive: true,
        fluid: !this.isRotatedVideo,
        plugins: {
          rotatePlayerPlugin: {},
        },
      },
      player: null,
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    isRotatedVideo: {
      get() {
        return this.rotate % 180 !== 0;
      },
    },
  },
  watch: {
    show(visible) {
      if (visible) {
        this.$refs.playerContainer.innerHTML = `<video id="videoPlayer" class="video-js" style="width: 100%"></video>`;
        this.displayVideo(this.media);
      } else {
        this.destroyPlayer();
      }
    },
  },
  mounted() {
    console.log('VideoDialog mounted');
  },
  beforeDestroy() {
    this.destroyPlayer();
    console.log('VideoDialog beforeDestroy');
  },
  methods: {
    destroyPlayer() {
      if (this.player) {
        this.player.dispose();
        console.log('dispose player');
        this.player = null;
      }
    },
    displayVideo(value) {
      if (!this.player || this.player.isDisposed()) {
        this.player = videojs('videoPlayer', this.playerOptions, () => {
          this.player.log('onPlayerReady', this);
          const player = this.player;
          this.$nextTick(() => {
            player.fluid(!this.isRotatedVideo);
            player.rotatePlayerPlugin().rotate({
              rotate: this.rotate,
              zoom: this.isRotatedVideo ? 1.8 : 1,
            });
            console.log('rotate');
          });
        });
      }

      this.$axios
        .$get(`/api/video-access/get-token/${value}`)
        .then((tokenResp) => {
          // this.url = process.env.M3_BASE_API + 'api/video/' + value;
          this.url = `${process.env.M3_BASE_API}${tokenResp.url.substring(1)}`;
          // this.playerOptions.sources[0].src = this.url;

          this.player.src([
            {
              src: this.url,
              type: 'video/mp4',
            },
          ]);
        });
    },
    download(file) {
      const url = this.url;
      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'video/mp4' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = file;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onPlayerPlay(player) {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onPlayerPause(player) {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onPlayerEnded(player) {},
  },
};
