
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { mapMutations } from 'vuex';
import ApiService from '@/services/api-service.js';
import moment from 'moment';
import GlobalDataTable from '~/components/global/DataTable.vue';

export default {
  name: 'TripMaintenance',
  components: {
    GlobalDataTable,
    VueJsonToCsv: () => {
      if (process.client) {
        return import('vue-json-to-csv');
      }
    },
    DriverModifyDialog: () => {
      if (process.client) {
        return import('@/components/dialog/DriverModifyDialog');
      }
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  data() {
    return {
      getWarning: [],
      search: '',
      warningList: [],
      
      dates: [
        new Date(new Date().setDate(new Date().getDate() - 1)),
        new Date(new Date().setDate(new Date().getDate())),
      ],
      vehicleDepartmentList: [],
      licencePlateList: [],
      driverList: [],
      vehdept: '',
      licences: '',
      drivers: '',
      vehdeptKey: '',
      licencesKey: '',
      driversKey: '',
      form: {},
      record: {},
      editDialog: false,
      // updateTripList: false,
      // fileName: null,
      msg: '',
      showMsg: false,
      resColor: 'green',

      summaryDialog: false,
      
      warningDef: [],
      warningDefDialog: false,
      warningDefinitionHtml: "",
    };
  },
  computed: {
    cIcon() {
      if (this.likesAllItems) return 'mdi-close-box';
      if (this.likesAllWarnings) return 'mdi-close-box';
      if (this.likesSomeItems) return 'mdi-minus-box';
      if (this.likesSomeWarnings) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    cWarningTypesList() {
      return this.warningDef.map((w) => ({ text:w.warningTypeText,value:w.warningType}))
    },
    likesAllWarnings() {
      return this.getWarning.length === this.cWarningTypesList.length;
    },
    likesSomeWarnings() {
      return this.getWarning.length > 0 && !this.likesAllWarnings;
    },
    cDownloadList() {
      const output = this.warningList.map((w) => {
        return {
          [this.$t('warningId')] : w.id,
          [this.$t('startTime')] : moment(
              w.startTime
            ).format('YYYY-MM-DD HH:mm:ss'),
          [this.$t('vehicle')] : w.vehiclePlate,
          [this.$t('vehicleDept')] : w.vehicleDepartment,
          [this.$t('warningType')] : this.$t(w.warningTypeText),
          ...['canSpeed','engineRev','coolantTemp','voltage','throttlePos','engineLoad'].reduce((p,c) => {
            ['start','max','min'].forEach((s) => {
              const field = `${s}${c.charAt(0).toUpperCase() + c.slice(1)}`
              p[this.$t(field)] = w[field] ?? 'n/a' // ? keep undefined or n/a
            })
            return p;
          },{})
        }
      })
      // console.log(`pms csv ${JSON.stringify(output)}`)
      return output;
    },
    headers() {
      return [
        { text: this.$t('warningId'), value: 'id' },
        { text: this.$t('startTime'), value: 'startTime', width: '120px' },
        // { text: this.$t('endTime'), value: 'endTime', width:'120px'},
        { text: this.$t('vehicle'), value: 'vehiclePlate' },
        { text: this.$t('vehicleDept'), value: 'vehicleDepartment' },
        
        { text: this.$t('warningType'), value: 'warningTypeText' },

        { text: this.$t('startCanSpeed'), value: 'startCanSpeed' },
        { text: this.$t('maxCanSpeed'), value: 'maxCanSpeed' },
        { text: this.$t('minCanSpeed'), value: 'minCanSpeed' },

        { text: this.$t('startEngineRev'), value: 'startEngineRev' },
        { text: this.$t('maxEngineRev'), value: 'maxEngineRev' },
        { text: this.$t('minEngineRev'), value: 'minEngineRev' },

        { text: this.$t('startCoolantTemp'), value: 'startCoolantTemp' },
        { text: this.$t('maxCoolantTemp'), value: 'maxCoolantTemp' },
        { text: this.$t('minCoolantTemp'), value: 'minCoolantTemp' },

        { text: this.$t('startVoltage'), value: 'startVoltage' },
        { text: this.$t('maxVoltage'), value: 'maxVoltage' },
        { text: this.$t('minVoltage'), value: 'minVoltage' },

        { text: this.$t('startThrottlePos'), value: 'startThrottlePos' },
        { text: this.$t('maxThrottlePos'), value: 'maxThrottlePos' },
        { text: this.$t('minThrottlePos'), value: 'minThrottlePos' },

        { text: this.$t('startEngineLoad'), value: 'startEngineLoad' },
        { text: this.$t('maxEngineLoad'), value: 'maxEngineLoad' },
        { text: this.$t('minEngineLoad'), value: 'minEngineLoad' },
      ];
    },
    summaryData() {
      const driverDistanceMap = this.warningList.reduce((prev,cur) => {
        if(cur.driver === '') {
          return prev
        }
        if (cur.driver in prev) {
          prev[cur.driver] = prev[cur.driver] + parseFloat(cur.distance)
        } else {
          prev[cur.driver] = parseFloat(cur.distance)
        }
        return prev
      }, {})
      
      return Object.entries(driverDistanceMap).map(([k,v]) => {
        const o = { 'driver': k, 'distance': parseFloat(v.toFixed(2) ) }
        
        return o;
      })
    },
    summaryHeaders() {
      return [
        { text: this.$t('driver'), value: 'driver'},
        { text: this.$t('distance'), value: 'distance'},
      ]
    }
  },
  watch: {
    // * watch warningdef dialog
    // ! same as the select box so get it anyways instead of only open definition dialog
    // ! so moved to mounted
    // ! show html 
    async warningDefDialog(val) {
      if (val) {
        const respJson = await this.$axios.$get('/api/CompanyWarningTypes/info', {params:{category:"PMS"}});
        this.warningDefinitionHtml = respJson?.message
      }
    },
    dates(val) {
      if (val[0] && val[1]) {
        this.queryForm();
      }
    },
    vehdept(val) {
      this.queryForm();
    },
    licences(val) {
      this.queryForm();
    },
    drivers(val) {
      this.queryForm();
    },
    getWarning(val) {
      this.queryForm();
    },
    // updateTripList() {
    //   // update when it true
    //   if (this.updateTripList) {
    //     this.queryForm();
    //     this.updateTripList = false;
    //   }
    // },
    // Change company
    async '$store.state.app.company'(val) {
      // * update available warning types
      await this.fetchWarningInfo()
      // this.queryForm();
      await this.vehicleList()
    },
    async '$store.state.app.locale'(val) {
      await this.fetchWarningInfo();
    }
  },
  async mounted() {
    await this.fetchWarningInfo()
  },
  async created() {
    await this.vehicleList();
  },
  methods: {
    ...mapMutations('app', ['get_Lang']),
    getLang() {
      this.get_Lang();
    },
    disabledBefore(date) {
      return this.warningDatePickerDisableDate(date);
    },
    disabledAfter() {
      return new Date();
    },
    // disabledAfter(date) {
    //   return date > new Date();
    // },
    async vehicleList() {
      const response = await ApiService.getTripOptions(this.$axios)
        if (response.items) {
          response.items.forEach((item, i) => {
            if (item.name === 'VehicleDepartment') {
              this.vehicleDepartmentList = item.value;
              if (this.vehicleDepartmentList) {
                this.vehicleDepartmentList.unshift('');
              }
              this.vehdeptKey = item.parameter;
            }
            if (item.name === 'LicencePlae') {
              this.licencePlateList = item.value;
              if (this.licencePlateList) {
                this.licencePlateList.unshift('');
              }
              this.licencesKey = item.parameter;
            }
            if (item.name === 'DriverName') {
              this.driverList = item.value;
              if (this.driverList) {
                this.driverList.unshift('');
              }
              this.driversKey = item.parameter;
            }
          });
          this.queryForm();
        }
      
    },
    queryForm() {
      const form = {};
      form[this.vehdeptKey] = this.vehdept;
      form[this.licencesKey] = this.licences;
      form[this.driversKey] = this.drivers;
      form.startDate = moment(this.dates[0]).format('YYYY-MM-DD');
      form.endDate = moment(this.dates[1]).format('YYYY-MM-DD');
      form.WarningTypes = this.getWarning.map((o) => typeof(o) === 'string' ? o : o.value);
      this.wrapShowloading(
      ApiService.getPmsWarnings(this.$axios, form).then((response) => {
        if(response?.data) {
          this.warningList = response.data;
        }
      })
      );
    },
    editItem(item) {
      this.record = item;
      this.editDialog = true;
    },
    openFileExplorer() {
      this.$refs.fileInputImport.click();
    },
    uploadFile(event) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      ApiService.postUploadFile(this.$axios, formData).then((response) => {
        if (!response.success) {
          this.msg = response.message;
          this.resColor = 'red';
        } else {
          this.msg = this.$t('UploadSuccess');
          this.resColor = 'green';
        }
        this.showMsg = true;
      });
      this.$refs.fileInputImport.value = '';
    },
    updateTripList(val) {
      this.queryForm();
      this.showMsg = true;
      this.msg = val.message;
    },
    showSummary() {
      this.summaryDialog = true;
    },
    // * show warning def
    async fetchWarningInfo() {
      // const availableWarningsValue = this.warningFilterOptions.map(
      //  (w) => w.value
      // );
      const respJson = await this.$axios.$get('/api/CompanyWarningTypes/settings', {params:{Category:"PMS"}});
      this.warningDef = respJson?.items
        // ?.filter(({ warningType }) =>
        //  availableWarningsValue.includes(warningType)
        // )
        .map((w) => {
          const { warningType, warningTypeText, fullname, description } = w;
          return { warningType, warningTypeText, fullname, description };
        });

        const warningLocale = respJson?.items.reduce((prev, cur) => {
          prev[cur.warningType] = cur.warningTypeText;
          return prev;
        }, {})
      // console.log(`${this.$store.state.app.locale} ${JSON.stringify(warningLocale)}`)
      this.$i18n.mergeLocaleMessage(this.$store.state.app.locale, warningLocale);
    },
    selectAllWarning() {
      this.$nextTick(() => {
        if (this.likesAllWarnings) {
          this.getWarning = [];
        } else {
          this.getWarning = this.cWarningTypesList.slice();
        }
      });
    },
  },
};
